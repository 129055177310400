
































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "STT tờ khai", value: "export_register_index" },
        { text: "Chú ý", value: "xnk_note" },
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: "Mô tả", value: "export_description" },
        { text: "Đơn vị tính", value: "export_unit" },
        { text: "Số lượng", value: "export_count" },
        { text: "Trị giá tính thuế", value: "export_taxed_amount" },
        { text: "Thuế nhập khẩu", value: "export_import_tax" },
        { text: "Thuế VAT", value: "export_vat" },
        { text: "Đơn giá tính thuế", value: "export_taxed_per_price" },
        { text: "Đơn giá hóa đơn", value: "export_bill_per_price" },
        { text: "Thành tiền hóa đơn", value: "export_bill_total_price" },
        { text: "VAT hóa đơn", value: "export_bill_vat" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      item: {},
      options: {},
      search: "",
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    };
  },
  computed: {
    formTitle() {
      return "Chỉnh sửa";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      this.item = await apiClient.truckGet(this.$route.params.id);
      const { items, count } = await apiClient.packageGetAll({
        options: this.options,
        filters: [
          {
            key: "truck_id",
            operator: "equal_to",
            value: this.$route.params.id,
          },
        ],
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const result = await apiClient.packageUpdate(this.editedItem, [
        "export_register_index",
        "export_vat",
        "export_import_tax",
        "export_count",
        "xnk_note",
      ]);
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    openFile() {
      const f = this.$refs.fileInput;
      f.click();
    },
    async uploadFile() {
      const file = this.$refs.fileInput.files[0];
      if (!file) {
        return;
      }
      const result = await apiClient.truckImportExportInfo(this.item.id, file);
      if (result) {
        await this.initialize();
      }
    },
    exportFile() {
      location.href = apiClient.truckExport(this.item, [
        "export_register_index",
        "package_id",
        "export_description",
        "export_unit",
        "export_count",
        "export_taxed_amount",
        "export_import_tax",
        "export_vat",
        "export_taxed_per_price",
        "export_bill_per_price",
        "export_bill_total_price",
        "export_bill_vat",
      ]);
    },
  },
});
